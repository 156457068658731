import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { AuditFeedItem } from '../../models/AuditFeed';
import { ApiResponse } from '../../models/ApiResponse';
import { ActivityFeedEntry } from './ActivityFeedEntry';

type ActivityFeedProps = {
  feed: ApiResponse<AuditFeedItem[]>;
  onLoadMore?: (pageNumber: number) => void;
};

const ActivityFeed: FC<ActivityFeedProps> = (props) => {
  const { t } = useTranslation('common');
  const { feed, onLoadMore } = props;
  const currentPage = props.feed.pageNumber || 0;
  const totalPages = props.feed.totalPages || 0;
  const hasEntries = !!feed.data?.length;

  return (
    (hasEntries || null) && (
      <div className="p-3">
        <>
          {feed.data.map((entry, i) => (
            <ActivityFeedEntry entry={entry} key={`new_${i}`} />
          ))}
        </>
        {currentPage < totalPages && (
          <div
            data-cy="activity-feed-view-more"
            className="mt-6 cursor-pointer text-center font-medium"
            onClick={() => onLoadMore && onLoadMore(currentPage + 1)}
          >
            {t('activity-feed.view-more')}
          </div>
        )}
      </div>
    )
  );
};

export default ActivityFeed;
