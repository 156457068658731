import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { AuditFeedItem } from '../../models/AuditFeed';
import { ClientFormUserRole } from '../../models/ClientFormUserRoles';
import DateUtils from '../../utils/DateUtils';
import { ProfileImageStack } from '../ownership/ProfileImageStack';
import { interpolate } from '../../utils/interpolation/InterpolationUtils';
import Tooltip from '../shared/Tooltip';

export type ActivityFeedEntryProps = {
  entry: AuditFeedItem;
};

export const ActivityFeedEntry: FC<ActivityFeedEntryProps> = (props) => {
  const { entry } = props;

  const { t } = useTranslation(['common']);
  const date = new Date(entry.createdUtc);
  const daysAgo = DateUtils.getDaysBetween(new Date(), date);
  const daysAgoKey = daysAgo === 0 ? 'today' : 'days-ago';
  const displayDate = t(`common:dow.${DateUtils.weekdays[date.getDay()]}.short`) + ` ${DateUtils.formatTime(date)}`;
  const text = interpolate(entry.text, entry.placeholders);

  return (
    <div className="border-primary-2 min-w-0 max-w-full border-b pb-5 pt-4">
      <div className="text-color-2 text-dpm-12 pb-2">
        <Trans t={t} i18nKey={`common:date.${daysAgoKey}`} count={daysAgo} values={{ daysAgo }} />
      </div>
      <div className="flex">
        <div className="flex flex-none pr-1">
          <div className="self-start">
            <ProfileImageStack
              users={[
                {
                  id: entry.createdById,
                  firstName: entry.createdBy.split(' ')[0],
                  lastName: entry.createdBy.split(' ').slice(1).join(' '),
                  role: ClientFormUserRole.Viewer,
                },
              ]}
            />
          </div>
        </div>
        <div className="pl-3">
          <Tooltip text={text} truncatedTextMode>
            {(tooltip) => (
              <div
                {...tooltip}
                className="text-dpm-14 text-color-3 line-clamp-3"
                style={{
                  wordBreak: 'break-word',
                  overflowWrap: 'break-word',
                }}
              >
                {text}
              </div>
            )}
          </Tooltip>
          <div className="text-dpm-12 text-color-3 pt-1 font-normal">{displayDate}</div>
        </div>
      </div>
    </div>
  );
};
