import { AuditFeedItem, AuditType } from '../models/AuditFeed';
import { ApiResponse } from '../models/ApiResponse';
import BaseService from './BaseService';

interface ActivityFeedFilter {
  clientId?: string;
  clientModuleId?: string;
  clientFormId?: string;
  feedType?: AuditType;
  feedSourceId?: string;
  pageSize?: number;
  pageNumber?: number;
}

export default class ActivityFeedService extends BaseService {
  public static getActivityFeed(activityFeedFilter: ActivityFeedFilter): Promise<ApiResponse<AuditFeedItem[]>> {
    return this.get('/v1/activity-feeds', {
      params: activityFeedFilter,
    });
  }
}
